import request from '@/utils/request';

export function resetGoodsSearch() {
  return request({
    url: `/admin/goods/search`,
    method: 'get',
  })
}

export function getPlatformParams() {
  return request({
    url: '/admin/systems/Preferences/point',
    method: 'get',
  })
}

export function setPlatformParams({payhour, payminute, payday, phone}) {
  return request({
    url: '/admin/systems/Preferences/point',
    method: 'put',
    data: {
      payhour,
      payminute,
      payday,
      phone,
    },
    log: {
      type: '3',
      name: '参数设置修改'
    }
  });
}

export function getBannerImages() {
  return request({
    url: '/admin/systems/Preferences/point',
    method: 'get',
  })
}

export function listActivityZones({page_no, page_size}) {
  return request({
    url: '/admin/systems/indexCategory/page',
    method: 'get',
    params: {
      page_no,
      page_size,
    }
  });
}

export function listActivityZoneDetails({page_no, page_size, name, id}) {
  return request({
    url: '/admin/systems/indexCategory/goodsPage',
    method: 'get',
    params: {
      page_no,
      page_size,
      name,
      id,
    }
  });
}

export function createActivityZone({category_name, sort, showable, showtype}) {
  return request({
    url: `/admin/systems/indexCategory/indexCategoryAdd`,
    method: 'post',
    data: {
      category_name,
      sort,
      showable,
      showtype,
    },
    log: {
      type: '3',
      name: '首页专区添加'
    }
  });
}

export function updateActivityZone({id, name, showtype}) {
  return request({
    url: `/admin/systems/indexCategory/${id}/edit`,
    method: 'put',
    data: {
      name,
      showtype,
    },
    log: {
      type: '3',
      name: '首页专区修改'
    }
  });
}

export function deleteActivityZone({id}) {
  return request({
    url: `/admin/systems/indexCategory/${id}/delete`,
    method: 'put',
    log: {
      type: '3',
      name: '首页专区删除'
    }
  });
}

export function setActivityZoneDisplayable({id, showable}) {
  return request({
    url: `/admin/systems/indexCategory/${id}/indexshowable`,
    method: 'put',
    data: {
      showable,
    },
    log: {
      type: '3',
      name: '首页专区修改'
    }
  });
}

export function setZoneActivityDisplayable({id, showable}) {
  return request({
    url: `/admin/systems/indexCategory/${id}/editgoods`,
    method: 'put',
    data: {
      showable,
    },
    log: {
      type: '3',
      name: '首页专区活动修改'
    }
  });
}

export function removeAllZoneActivities({ids}) {
  return request({
    url: `/admin/systems/indexCategory/${ids}`,
    method: 'delete',
    log: {
      type: '3',
      name: '首页专区活动删除'
    }
  });
}

export function setActivityZoneSort({id, type, sort}) {
  return request({
    url: `/admin/systems/indexCategory/${id}/updateSort`,
    method: 'put',
    data: {
      type,
      sort,
    }
  });
}

export function setZoneActivitiesSort({id, type, sort, idx_category_id}) {
  return request({
    url: `/admin/systems/indexCategory/${id}/updategoodSort`,
    method: 'put',
    data: {
      type,
      sort,
      idx_category_id
    },
  });
}

export function appendActivitiesToZone({id, indexCategoryGoods}) {
  return request({
    url: `/admin/systems/indexCategory`,
    method: 'post',
    isJson: true,
    params: {id},
    data: indexCategoryGoods,
    log: {
      type: '3',
      name: '首页专区活动添加'
    }
  });
}

export function listLatestActivities({date_type, page_no, page_size}) {
  return request({
    url: `/admin/systems/indexCategoryGoods`,
    method: 'get',
    isJson: true,
    params: {
      page_no,
      page_size,
      date_type
    }
  });
}

export function listCategory({ parentId }) {
  return request({
    url: `/admin/goods/categories/${parentId}/children`,
    method: 'get'
  });
}
/** 查询资讯列表 */
export function listNews(params) {
  return request({
    url: `/admin/consult/getConsult`,
    headers: {
      'application-source': '2'
    },
    method: 'get',
    isJson: true,
    params
  });
}
/** 添加资讯内容 */
export function addNews(data) {
  return request({
    url: `/admin/consult/add`,
    method: 'post',
    headers: {
      'application-source': '2'
    },
    isJson: true,
    data
  });
}
/**
 * 编辑资讯内容
 */
export function editNews(id, data) {
  return request({
    url: `/admin/consult/editConsult/${id}`,
    method: 'post',
    headers: {
      'application-source': '2'
    },
    isJson: true,
    data
  });
}
/** 删除资讯 */
export function deleteNews(ids) {
  return request({
    url: `/admin/consult/delete/${ids}`,
    method: 'delete',
    headers: {
      'application-source': '2'
    },
    isJson: true,
  });
}
/**
 * 查询资讯详情
 */
export function getNews(id) {
  return request({
    url: `/admin/consult/getPlatformConsult/${id}`,
    method: 'get',
    headers: {
      'application-source': '2'
    },
    isJson: true,
  });
}
/**
 * 更新资讯上下架
 */
export function changeNewsStatus(id, status) {
  return request({
    url: `/admin/consult/${id}/changeStatus`,
    method: 'put',
    headers: {
      'application-source': '2'
    },
    isJson: true,
    params: {
      status
    }
  });
}
