<template>
  <div class="page-latest-zone">
    <el-card>
      <div slot="header" class="row no-gutters align-items-center">
        <div class="col">上新专区</div>
        <div class="col-auto">
          时间范围：
        </div>
        <div class="col-auto">
          <el-select v-model="selectedTime" placeholder="请选择时间" @change="load">
            <el-option
              v-for="item in timeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>

      <el-table
        :data="results.data"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          prop="goods_sn"
          label="活动编号">
        </el-table-column>

        <el-table-column
          prop="goods_name"
          label="活动名称">
        </el-table-column>

        <el-table-column
          prop="shop_name"
          width="180"
          label="所属商户">
        </el-table-column>

        <el-table-column
          prop="goods_name"
          width="180"
          label="所属分类">
          <template slot-scope="scope">
            <!-- {{ scope.row.cat_first_name }}{{ scope.row.cat_second_name ? `-${scope.row.cat_second_name}` : '' }} -->
            {{scope.row.category_name}}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="是否显示"
          width="180">
          <template slot-scope="scope">
            <!-- <el-switch v-model="scope.row.showable" @change="setActivityDisplayable(scope.row)"/> -->
            <el-switch
              v-model="scope.row.showable"
              :active-value="1"
              :inactive-value="0"
              @change="setActivityDisplayable(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>

      <div class="row pt-3">
        <div class="col"></div>
        <div class="col-auto">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="results.data_total"
            :page-size="20"
            :current-page="results.page_no"
            @current-change="currentPageChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script lang="js">
import LatestZonePage from './latest-zone';

export default LatestZonePage;
</script>

<style lang="scss">
@import "./latest-zone";
</style>
