import {Component, Vue} from 'vue-property-decorator';
import {listLatestActivities, setZoneActivityDisplayable} from "@/api/$platform";

export default @Component()
class LatestZonePage extends Vue {
  results = {page_no: 1, data: []};
  selectedTime = 'week';
  timeOptions = [
    {
      label: '本周',
      value: 'week',
    },
    {
      label: '本月',
      value: 'month',
    },
  ];

  mounted() {
    this.load();
  }

  load() {
    listLatestActivities({
      date_type: this.selectedTime,
      page_no: this.results.page_no,
      page_size: 20,
    }).then(resp => {
      this.results = resp;
    });
  }

  setActivityDisplayable(row) {
    setZoneActivityDisplayable({
      id: row.id,
      showable: row.showable ? 1 : 0
    }).then(resp => {
      if (row.showable) {
        this.$message.success(`活动已显示`);
      } else {
        this.$message.warning(`活动已隐藏`);
      }
    });
  }

  handleSelectionChange(e) {
    this.selectedData = e;
  }

  currentPageChange(index) {
    this.results.page_no = index;
    this.load();
  }
}
